<template functionnal>
  <div class="template pb-4">
    <div class="template__content">
      <div class="row">
        <div class="col-12">
          <h1>Nos ateliers | <span class="marker-effect">Ouverts à tous</span></h1>
        </div>
      </div>

      <div class="row">
        <div class="col-6 mt-4">
          <div class="row">
            <h5>
              Les ateliers d’informatiques pour viser l’autonomie dans les démarches de la vie
              quotidienne
            </h5>
          </div>
          <div class="row">
            <div class="col-12 p-0 font-bold">Public</div>
            <div>Public bénéficiaire de l'association et habitant de Paris 13e</div>
          </div>
          <div class="row">
            <div class="col-12 p-0 font-bold">Pré-requis</div>
            <div>Aucun</div>
          </div>
          <div class="row">
            <div class="col-12 p-0 font-bold">Objectifs</div>
            <div>
              Apprendre à utiliser un ordinateur, utiliser un traitement de texte, aller sur
              Internet, créer une adresse mail, etc...<br />
            </div>
          </div>
          <div class="row">
            <div class="col-12 p-0 font-bold">Type d'activités</div>
            <div>
              Atelier ponctuels
            </div>
          </div>
          <div class="row">
            <div class="col-12 p-0 font-bold">Tarif</div>
            <div>
              Gratuit
            </div>
          </div>

          <div class="row mt-3">
            <h6>Descriptions des cours</h6>

            <div>
              1 fois par mois sur 2 jours (2 X 3 heures)
              <ul>
                <li>Utiliser un ordinateur pour envoyer des emails.</li>
                <li>Utiliser internet pour faire ses démarches administratives en ligne.</li>
                <li>Rédiger et envoyer un courrier pour un organisme administratif.</li>
                <li>
                  S’informer en autonomie sur les aides et les droits qui existent dans ma ville.
                </li>
                <li>Organiser une sortie culturelle et réserver une activité en ligne.</li>
                <li>
                  Communiquer et se former en téléconférence et avec une messagerie instantanée.
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-6 mt-4">
          <div class="row">
            <h5>Les Ateliers d'insertion professionnelle</h5>
          </div>

          <div class="row">
            <div class="col-12 p-0 font-bold">Public</div>
            <div>Public bénéficiaire de l&#39;association et les habitants de Paris 13e</div>
          </div>

          <div class="row">
            <div class="col-12 p-0 font-bold">Pré-requis</div>
            <div>
              S'avoir s'exprimer un minimum en français, être capable de comprendre une consigne et
              d'échanger à partir de phrases simples.
            </div>
          </div>

          <div class="row">
            <div class="col-12 p-0 font-bold">Objectifs</div>
            <div>Devenir autonome dans la recherche d'un emploi.</div>
          </div>

          <div class="row mt-6">
            <h6>Descriptions des cours</h6>

            <div>
              Action sur demande
              <ul>
                <li><span class="font-semibold">Thématique des ateliers:</span></li>
                <ul>
                  <li>L'emploi en France: comment ça marche ?</li>
                  <li>Atelier CV</li>
                  <li>Préparation à l'entretien d'embauche</li>
                  <li>Comment chercher un emploi</li>
                  <li>Pôle Emploi, comment ça marche ?</li>
                  <li>Actualité de l'emploi : les secteurs qui recrutent. ?</li>
                </ul>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
